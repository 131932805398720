// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bio-js": () => import("./../../../src/pages/bio.js" /* webpackChunkName: "component---src-pages-bio-js" */),
  "component---src-pages-cat-bio-js": () => import("./../../../src/pages/cat/bio.js" /* webpackChunkName: "component---src-pages-cat-bio-js" */),
  "component---src-pages-cat-contact-js": () => import("./../../../src/pages/cat/contact.js" /* webpackChunkName: "component---src-pages-cat-contact-js" */),
  "component---src-pages-cat-index-js": () => import("./../../../src/pages/cat/index.js" /* webpackChunkName: "component---src-pages-cat-index-js" */),
  "component---src-pages-cat-manifest-js": () => import("./../../../src/pages/cat/manifest.js" /* webpackChunkName: "component---src-pages-cat-manifest-js" */),
  "component---src-pages-cat-metode-js": () => import("./../../../src/pages/cat/metode.js" /* webpackChunkName: "component---src-pages-cat-metode-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-fr-bio-js": () => import("./../../../src/pages/fr/bio.js" /* webpackChunkName: "component---src-pages-fr-bio-js" */),
  "component---src-pages-fr-contact-js": () => import("./../../../src/pages/fr/contact.js" /* webpackChunkName: "component---src-pages-fr-contact-js" */),
  "component---src-pages-fr-index-js": () => import("./../../../src/pages/fr/index.js" /* webpackChunkName: "component---src-pages-fr-index-js" */),
  "component---src-pages-fr-manifeste-js": () => import("./../../../src/pages/fr/manifeste.js" /* webpackChunkName: "component---src-pages-fr-manifeste-js" */),
  "component---src-pages-fr-methode-js": () => import("./../../../src/pages/fr/methode.js" /* webpackChunkName: "component---src-pages-fr-methode-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manifesto-js": () => import("./../../../src/pages/manifesto.js" /* webpackChunkName: "component---src-pages-manifesto-js" */),
  "component---src-pages-metodo-js": () => import("./../../../src/pages/metodo.js" /* webpackChunkName: "component---src-pages-metodo-js" */)
}

